import API_LINKS from "@/view/pages/painel/automacao/api.links";
import ApiService from "@/core/services/api.service";

export default class Utils {
  static setInterval(funcao, timeout) {
    return setInterval(funcao, timeout);
  }

  /**
   * Retorna o link do webhook da campanha.
   * @param campanha - Campanha a ser usada para pegar o token do webhook.
   * @returns {string} - Link do webhook da campanha.
   */
  static getLinkWebhookCampanha(campanha) {
    if (!campanha.webhook || !campanha.webhook.mdw_token) {
      return "";
    }
    return (
      process.env.VUE_APP_COMUNICANTE_API +
      API_LINKS.campanhaWebhook +
      "?token=" +
      (campanha ? campanha.webhook.mdw_token : "")
    );
  }

  /**
   * Retorna os recursos ativos de uma conta.
   * @param contaId - Id da conta a ser usada para pegar os recursos ativos. Caso seja null,
   * retorna os recursos ativos da conta do usuário logado.
   * @returns {Promise<unknown>}
   */
  static getRecursosByConta(contaId = null) {
    const path = contaId
      ? API_LINKS.recursosAtivos + "/" + contaId
      : API_LINKS.recursosAtivos;
    return ApiService.get(path, "", true);
  }

  /**
   * Formata a data de timestamp para o formato brasileiro.
   * @param timestamp - Data de timestamp a ser formatada.
   * @returns {string} - Data formatada.
   */
  static formateDateTimestampToBr(timestamp) {
    if (!timestamp) {
      return "";
    }
    const date = new Date(timestamp);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }
}
