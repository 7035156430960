<template>
  <div>
    <button
      type="button"
      class="btn btn-wellon-google"
      :class="{ 'btn-block': block }"
      @click="handleAuthClick"
    >
      <img
        width="20"
        height="20"
        src="/icon/google-color.svg"
        loading="lazy"
        alt="Google"
        class="mr-3"
      />
      <span>Login com o Google</span>
    </button>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";

export default {
  props: {
    block: {
      type: Boolean,
      default: false
    },
    resource: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      popup: null
    };
  },

  methods: {
    handleAuthClick() {
      ApiService.get("oauth2/google/login", "", true, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API,
        params: {
          resource: this.resource
        }
      }).then((res) => {
        const authUrl = res.data.data;
        this.openPopup(authUrl);
      });
    },

    openPopup(url) {
      window.removeEventListener("message", this.receiveMessage);

      const width = 460;
      const height = 540;
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 4;
      const features = `popup=true,top=${top},left=${left},width=${width},height=${height}`;

      this.popup = window.open(url, "GoogleLoginPopup", features);

      window.addEventListener("message", this.receiveMessage);
    },

    receiveMessage(event) {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data } = event;

      if (data.source === "wellon-comunicante") {
        this.$emit("oauth-data", data.data);
      }
    }
  }
};
</script>
