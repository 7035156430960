<template>
  <div>
    <b-modal
      id="mdlFeriado"
      ref="mdlFeriado"
      title="Cadastrar Feriado"
      ok-title="Salvar"
      ok-only
      @ok="salvarFeriado"
      @hidden="resetarFeriado"
      size="md"
    >
      <b-form
        id="frmFeriado"
        ref="frmFeriado"
        @submit.prevent="salvarFeriado"
        @reset.prevent="resetarFeriado"
      >
        <b-form-group>
          <label class="form-control-label"
            >Nome: <span class="text-danger">*</span></label
          >
          <b-form-input
            v-model="frmFeriado.fer_nome"
            name="nome"
            placeholder="Nome do feriado"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label class="form-control-label"
            >Data: <span class="text-danger">*</span></label
          >

          <b-form-datepicker
            v-model="frmFeriado.fer_dia"
            class="mb-2"
            name="data"
            required
          ></b-form-datepicker>
        </b-form-group>
        <!-- fer_ativo -->
        <div class="form-group row">
          <label class="px-4 col-form-label"
            >Ativo: <span class="text-danger">*</span></label
          >
          <div class="col-3">
            <span class="switch">
              <label>
                <input
                  type="checkbox"
                  name="ativo"
                  v-model="frmFeriado.fer_ativo"
                />
                <!-- true-value="A"
                  false-value="I" -->
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-card no-body>
      <b-tabs v-model="tabIndex" card>
        <b-tab
          title="Facebook"
          v-if="
            (checkSuperAdmin || isAccountAdmin) &&
            recursoAtivoConta('WHATSAPP_OFICIAL')
          "
        >
          <div v-if="!conectadoFacebook">
            <h5 class="card-label" style="margin-bottom: 8px">
              Faça o login com o Facebook
            </h5>
            <div class="d-flex" style="margin-bottom: 12px">
              <div class="custom-badge gray"></div>
              Para acessar nossos recursos pelo WhatsApp oficial, é necessário
              realizar essa ação. <br />
              Lembre-se de fazer login com o Facebook para cada conta utilizada.
            </div>
          </div>
          <div v-else>
            <h5 class="card-label" style="margin-bottom: 8px">
              <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Você está
              conectado ao Facebook!
            </h5>
            <div class="d-flex" style="margin-bottom: 12px">
              <div class="custom-badge gray"></div>
              Para conectar mais uma conta, realize o fluxo de login novamente.
            </div>
          </div>
          <FacebookLoginButton @login-success="handleLoginSuccess" />
        </b-tab>
        <b-tab
          title="Google"
          @click="fetchGoogleData"
          v-if="
            (checkSuperAdmin || isAccountAdmin) &&
            recursoAtivoConta('GOOGLE_INTEGRATION')
          "
        >
          <h5 class="card-label" style="margin-bottom: 8px">
            Faça o login com o Google
          </h5>
          <div class="d-flex" style="margin-bottom: 12px">
            <div class="custom-badge gray"></div>
            Para acessar nossos recursos com a integração do Google, é
            necessário realizar essa ação. <br />
            Lembre-se de fazer login com o Google para cada conta utilizada.
          </div>
          <div class="row col-3">
            <GoogleLoginButton
              @oauth-data="setOAuthData"
              resource="GOOGLE_INTEGRATION"
              class="w-100 mb-2"
              block
            />
          </div>
          <div
            class="row"
            v-if="googleAccessCredentials && googleAccessCredentials.length"
          >
            <div class="col-12" data-app>
              <v-card>
                <v-data-table
                  :headers="fieldsGoogle"
                  :items="googleAccessCredentials"
                  class="elevation-1 data-table-custom-margin-select"
                  :page.sync="tableGoogleCurrentPage"
                  :footer-props="{
                    itemsPerPageText: 'Itens por página',
                    itemsPerPageOptions: [5, 10, 20, 25]
                  }"
                >
                  <template #item.id="{ item }">
                    <div class="text-center">{{ item.id }}</div>
                  </template>
                  <template #item.email="{ item }">
                    <div style="display: flex; align-items: center">
                      <v-avatar v-if="item.avatar" size="32" class="mr-2">
                        <img :src="item.avatar" alt="Avatar do usuário" />
                      </v-avatar>
                      <span>{{ item.email }}</span>
                    </div>
                  </template>
                  <template #item.status="{ item }">
                    <div class="text-center">{{ item.status }}</div>
                  </template>
                  <template #item.created_at="{ item }">
                    <span>{{
                      Utils.formateDateTimestampToBr(item.created_at)
                    }}</span>
                  </template>
                  <template #item.updated_at="{ item }">
                    <span>{{
                      Utils.formateDateTimestampToBr(item.updated_at)
                    }}</span>
                  </template>
                  <template #item.approvedScopes="{ item }">
                    <v-chip
                      v-for="(scope, index) in item.approvedScopes"
                      :key="index"
                      color="#DB4437"
                      class="ma-1 m-1"
                      small
                      dark
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ scope }}</span>
                        </template>
                        <span>{{ scope }}</span>
                      </v-tooltip>
                    </v-chip>
                  </template>
                  <template #item.acoes="{ item }">
                    <v-btn
                      color="#DB4437"
                      class="btn-remove"
                      @click="excluirConexao(item.sistema_licenciada_id)"
                      dark
                    >
                      <v-icon left color="white">mdi-delete</v-icon>
                      Remover
                    </v-btn>
                  </template>
                  <template
                    #footer.page-text="{ pageStart, pageStop, itemsLength }"
                  >
                    Exibindo {{ pageStart }} - {{ pageStop }} de
                    {{ itemsLength }} resultados
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </b-tab>
        <b-tab
          title="Contas WhatsApp"
          v-if="
            (checkSuperAdmin || isAccountAdmin) &&
            recursoAtivoConta('WHATSAPP_OFICIAL') &&
            conectadoFacebook
          "
        >
          <contas-whatsapp v-if="tabIndex == 2"></contas-whatsapp>
        </b-tab>
        <!-- Tab para cadastrar feriados -->
        <b-tab title="Feriados">
          <b-card-text>
            <b-form
              id="frmCadastrarRespostaAutomatica"
              ref="frmCadastrarRespostaAutomatica"
              @submit.prevent="salvarFeriados"
            >
              <b-form-group>
                <label class="form-control-label"
                  >Feriados: <span class="text-danger">*</span></label
                >
                <MultiSelect
                  class="col"
                  v-model="feriadosSelecionados"
                  :options="opcoesFeriados"
                  optionLabel="text"
                  placeholder="Feriados"
                  size="lg"
                  @input="toggleFeriadosInput"
                />
              </b-form-group>

              <b-row>
                <b-col md="6" class="text-left">
                  <b-button variant="secondary" @click="importarFeriados"
                    >Importar Feriados</b-button
                  >
                  <b-button
                    variant="secondary"
                    class="ml-2"
                    @click="mostrarModalFeriado"
                    >Criar Feriado</b-button
                  >
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button variant="primary" @click="salvarFeriados"
                    >Salvar</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
        <b-tab title="Ações Padrões" v-if="chatAtivo">
          <b-card-text>
            <b-form
              id="frmAcaoPadraoRecebimento"
              ref="frmAcaoPadraoRecebimento"
              @submit.prevent="salvarAcaoPadraoRecebimento"
            >
              <b-form-group>
                <label class="form-control-label"
                  >Ação: <span class="text-danger">*</span></label
                >
                <b-form-select
                  v-model="frmAcaoPadraoRecebimento.acao"
                  :options="optAcoes"
                  name="acao"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                v-show="['RS', 'RI'].includes(frmAcaoPadraoRecebimento.acao)"
              >
                <label class="form-control-label"
                  >Mensagem: <span class="text-danger">*</span></label
                >
                <textarea-emoji
                  :value="frmAcaoPadraoRecebimento.mensagem"
                  name="mensagem"
                  @updated="atualizarMensagem"
                >
                </textarea-emoji>
              </b-form-group>

              <b-form-group>
                <label class="form-control-label"
                  >Habilitar exclusão de conversas com mais quantos dias:
                  <span class="text-danger">*</span></label
                >
                <b-form-select
                  v-model="frmAcaoPadraoRecebimento.excluirConversasAntigas"
                  :options="optExcluirConversasAntigas"
                  name="excluirConversasAntigas"
                ></b-form-select>
              </b-form-group>

              <div class="form-group row">
                <label class="px-4 col-form-label"
                  >Habilitar resposta em caso de Chamadas</label
                >
                <div class="col-3">
                  <span class="switch">
                    <label>
                      <input
                        type="checkbox"
                        name="respostaChamada"
                        v-model="frmAcaoPadraoRecebimento.respostaChamada"
                        true-value="R"
                        false-value="I"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <b-form-group
                v-show="frmAcaoPadraoRecebimento.respostaChamada == 'R'"
              >
                <label class="form-control-label"
                  >Mensagem: <span class="text-danger">*</span></label
                >
                <textarea-emoji
                  :value="frmAcaoPadraoRecebimento.textoRespostaChamada"
                  name="textoRespostaChamada"
                  @updated="atualizarTextoRespostaChamada"
                >
                </textarea-emoji>
              </b-form-group>

              <b-row>
                <b-col md="12" class="text-right">
                  <b-button type="submit" variant="primary">Salvar</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
        <!-- Aba para cadastrar arquivos -->
        <b-tab title="Arquivos" v-if="role == 'admin'">
          <arquivos v-if="tabIndex == 4"></arquivos>
        </b-tab>
        <!-- Aba para cadastrar previsão de faturamento de setor -->
        <b-tab title="Previsão de Faturamento" v-if="role == 'admin'">
          <previsao-faturamento v-if="tabIndex == 5"></previsao-faturamento>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import MultiSelect from "primevue/multiselect";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import moment from "moment";
import API_LINKS from "./automacao/api.links";
// import the feriados.json
import feriadosJson2024 from "./feriados_2024.json";
import feriadosJson2025 from "./feriados_2025.json";

import Arquivos from "./bi/Arquivos.vue";
import PrevisaoFaturamento from "./bi/PrevisaoFaturamento.vue";

import FacebookLoginButton from "@/view/components/FacebookLoginButton.vue";
import ContasWhatsapp from "./ContasWhatsapp.vue";
import GoogleLoginButton from "@/view/components/GoogleLoginButton.vue";
import Utils from "@/core/utils/Utils";

export default {
  name: "configuracao",
  components: {
    GoogleLoginButton,
    MultiSelect,
    Arquivos,
    PrevisaoFaturamento,
    FacebookLoginButton,
    ContasWhatsapp
  },
  data() {
    return {
      googleAccessCredentials: null,
      fieldsGoogle: [
        { value: "id", text: "Código" },
        { value: "titulo", text: "Título" },
        { value: "email", text: "Usuário" },
        { value: "status", text: "Status" },
        { value: "token", text: "Token" },
        { value: "approvedScopes", text: "Escopos" },
        { value: "updated_at", text: "Atualizado em" },
        { value: "created_at", text: "Criado em" },
        { value: "acoes", text: "Ações", sortable: false }
      ],
      tableGoogleCurrentPage: 1,
      oAuthUserInfo: null,
      frmEsquentaChip: {
        esquentaChip: "I"
      },
      feriadosParaToggle: [],
      frmFeriado: {
        fer_nome: "",
        fer_dia: "",
        fer_ativo: false
      },
      opcoesFeriados: [],
      feriadosSelecionados: [],
      frmAcaoPadraoRecebimento: {
        provedor: null,
        acao: "IG",
        mensagem: "",
        respostaChamada: "I",
        textoRespostaChamada: "",
        excluirConversasAntigas: 0
      },
      optAcoes: [
        { value: "SV", text: "Salvar respostas" },
        { value: "RS", text: "Salvar respostas e enviar mensagem padrão" },
        { value: "RI", text: "Ignorar respostas e enviar mensagem padrão" },
        { value: "IG", text: "Ignorar respostas" }
      ],
      optExcluirConversasAntigas: [
        { value: "0", text: "Não Excluir" },
        { value: "7", text: "Excluir com mais de 7 dias" },
        { value: "15", text: "Excluir com mais de 15 dias" },
        { value: "30", text: "Excluir com mais de 30 dias" },
        { value: "60", text: "Excluir com mais de 60 dias" },
        { value: "90", text: "Excluir com mais de 90 dias" },
        { value: "180", text: "Excluir com mais de 180 dias" }
      ],
      chatAtivo: false,
      campos: [
        {
          key: "comando",
          label: "Comando",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "resposta",
          label: "Resposta",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "actions",
          label: "Ações",
          sortable: false,
          tdClass: "align-middle"
        }
      ],
      role: "",
      tabIndex: 0,
      conectadoFacebook: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Configuração" }]);
    this.inicializar();
    this.pegarFeriados();
  },
  methods: {
    async fetchGoogleData() {
      if (this.googleAccessCredentials) {
        return;
      }
      ApiService.get("oauth2/google/grantAccessCredentials", "", true, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API,
        params: {
          resource: this.resource
        }
      })
        .then((res) => {
          this.googleAccessCredentials = res.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "Erro ao buscar dados do Google.",
            icon: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    async excluirConexao(id) {
      Swal.fire({
        title: "Excluir Conexão",
        text: "Deseja realmente excluir essa conexão?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      }).then((result) => {
        if (result.value) {
          ApiService.delete("oauth2/google/revoke/" + id, null, {
            baseURL: process.env.VUE_APP_COMUNICANTE_API
          })
            .then(() => {
              const index = this.googleAccessCredentials.findIndex(
                (x) => x.sistema_licenciada_id === id
              );
              if (index !== -1) {
                this.googleAccessCredentials.splice(index, 1);
              }
              Swal.fire({
                title: "Sucesso!",
                text: "Conexão excluída com sucesso.",
                icon: "success",
                confirmButtonText: "Ok"
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "Erro!",
                text: "Erro ao excluir conexão.",
                icon: "error",
                confirmButtonText: "Ok"
              });
            });
        }
      });
    },
    salvarFeriados() {
      let feriado_para_toggle = this.feriadosSelecionados.map(
        (feriado) => feriado.feriado_id
      );
      ApiService.post(API_LINKS.feriado + "/toggle", { feriado_para_toggle })
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: "Feriados ativos com sucesso.",
            icon: "success",
            confirmButtonText: "Ok"
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "Erro ao salvar feriados.",
            icon: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    setOAuthData(user) {
      if (!user?.email || !user?.token || !user?.baseUrl) {
        Swal.fire(
          "Erro:",
          "Não foi possível obter os dados do usuário, tente novamente!",
          "error"
        );
      } else {
        this.oAuthUserInfo = { ...user };
      }
    },
    toggleFeriadosInput(val) {
      // console.log(val);
    },
    dataValidaParaFeriado(data) {
      // if the date is today or before, return false
      if (moment(data).isBefore(moment(), "day")) {
        Swal.fire({
          title: "Data Inválida!",
          text: "Cadastre um feriado com data futura.",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return false;
      }
      return true;
    },
    resetarFeriado() {
      this.frmFeriado = {
        fer_nome: "",
        fer_dia: "",
        fer_ativo: false
      };
    },
    importarFeriados() {
      Swal.fire({
        title: "Importar Feriados",
        text: "Deseja importar os feriados do ano atual?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      }).then((result) => {
        if (result.value) {
          let payload = feriadosJson2024;
          if (moment().year() == 2025) {
            payload = feriadosJson2025;
          }
          ApiService.post(API_LINKS.feriado + "/importar", payload).then(
            (response) => {
              if (!response.data.success) {
                Swal.fire({
                  title: "Não Foi Possivel Importar!",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonText: "Ok"
                });
                return;
              }
              this.pegarFeriados();
              Swal.fire({
                title: "Sucesso!",
                text: response.data.message,
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          );
        }
      });
    },
    salvarFeriado() {
      if (!this.dataValidaParaFeriado(this.frmFeriado.fer_dia)) return;
      const payload = {};
      payload.feriado = this.frmFeriado;
      ApiService.post(API_LINKS.feriado, payload).then((response) => {
        this.resetarFeriado();
        this.$bvModal.hide("mdlFeriado");
        Swal.fire({
          title: "Sucesso!",
          text: response.message,
          icon: "success",
          confirmButtonText: "Ok"
        });
      });
    },
    mostrarModalFeriado() {
      this.$bvModal.show("mdlFeriado");
    },
    pegarFeriados() {
      ApiService.get(API_LINKS.feriado).then((response) => {
        const ativos = [];
        this.opcoesFeriados = response.data.feriados.map((feriado) => {
          const feriadoObj = {
            ...feriado,
            text:
              feriado.fer_nome +
              " - " +
              moment(feriado.fer_dia).format("DD/MM/YYYY"),
            code: feriado.fer_id
          };
          if (feriado.fer_ativo) ativos.push(feriadoObj);
          return feriadoObj;
        });
        this.feriadosSelecionados = ativos;
      });
    },
    inicializar() {
      ApiService.get("configuracao").then((response) => {
        const data = response.data.data;
        this.frmAcaoPadraoRecebimento = data.chat;
        this.chatAtivo = data.chatAtivo;
      });

      ApiService.get("nps").then((response) => {
        this.role = response.data.data.role;
      });

      ApiService.get("whatsapp/conectado").then((response) => {
        this.conectadoFacebook = response.data.conectado;
      });
    },
    atualizarMensagem(mensagem) {
      this.frmAcaoPadraoRecebimento.mensagem = mensagem;
    },
    atualizarTextoRespostaChamada(mensagem) {
      this.frmAcaoPadraoRecebimento.textoRespostaChamada = mensagem;
    },
    salvarAcaoPadraoRecebimento() {
      this.fv1.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post(
            "configuracao/salvar-acao-padrao-recebimento",
            this.frmAcaoPadraoRecebimento
          )
            .then(() => {
              for (const el in this.fv1.getFields()) {
                this.fv1.updateFieldStatus(el, "NotValidated");
              }

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },
    handleLoginSuccess(data) {
      this.conectadoFacebook = true;

      Swal.fire({
        title: "MENSAGEM",
        text: "Conta conectada com sucesso!",
        icon: "success",
        heightAuto: false
      });
    }
  },
  computed: {
    ...mapGetters([
      "validatorErrors",
      "checkSuperAdmin",
      "isAccountAdmin",
      "recursoAtivoConta"
    ]),
    Utils() {
      return Utils;
    }
  },
  watch: {
    feriadosSelecionados: {
      handler: function (val) {
        // feriadosParaToggle
        // if(this.feriadosParaToggle.find(x => x.id == val.id)){
        //   this.feriadosParaToggle = this.feriadosParaToggle.filter(x => x.id != val.id)
      },
      deep: true
    },
    chatAtivo(ativo) {
      if (ativo) {
        this.$nextTick(() => {
          const frmAcaoPadraoRecebimento = document.getElementById(
            "frmAcaoPadraoRecebimento"
          );

          this.fv1 = formValidation(frmAcaoPadraoRecebimento, {
            fields: {
              acao: {
                validators: {
                  notEmpty: {
                    message: "Campo obrigatório."
                  },
                  blank: {}
                }
              },
              mensagem: {
                validators: {
                  callback: {
                    message: "Campo obrigatório com a ação selecionada.",
                    callback: function (input) {
                      const acao =
                        frmAcaoPadraoRecebimento.querySelector(
                          "[name=acao]"
                        ).value;
                      const acoesExcluidas = ["SV", "IG"];

                      return acoesExcluidas.includes(acao)
                        ? true
                        : input.value !== "";
                    }
                  },
                  blank: {}
                }
              },
              textoRespostaChamada: {
                validators: {
                  callback: {
                    message:
                      "Campo obrigatório quando resposta estiver habilitada.",
                    callback: function (input) {
                      const respostaChamada =
                        frmAcaoPadraoRecebimento.querySelector(
                          "[name=respostaChamada]"
                        );
                      return !respostaChamada.checked
                        ? true
                        : input.value !== "";
                    }
                  },
                  blank: {}
                }
              }
            },
            plugins: {
              trigger: new Trigger(),
              bootstrap: new Bootstrap()
            }
          });
        });
      }
    }
  }
};
</script>

<style>
.btn-remove {
  background-color: #db4437 !important; /* Vermelho Google */
  color: white !important;
}

.v-data-table-header th {
  font-weight: bold;
  white-space: nowrap;
  text-align: center !important;
}

.data-table-custom-margin-select .v-select__selections {
  margin-left: 5px;
}

.v-data-table td {
  border-right: 2px solid rgba(224, 224, 224, 0.2);
}

.v-data-table td:last-child {
  border-right: none;
}
</style>
